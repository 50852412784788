<template>
  <v-dialog
    v-model="isOpen"
    max-height="800"
    max-width="1000"
    persistent
  >
    <v-card>
      <v-card-title class="justify-center">
        <v-container>
          <v-layout row>
             <v-flex xs6 class="title">Biometric File</v-flex>
          </v-layout>
          <v-layout row v-if="hasScores" class="pt-2">
             <v-flex xs6>Score: {{score}}</v-flex>
          </v-layout>
          <v-layout row v-if="hasScores">
             <v-flex xs6>Threshold: {{threshold}}</v-flex>
          </v-layout>
          <v-layout row class="pt-3">
            <v-flex xs6 class="text-xs-center">
              <v-select
                label="Select Biometric File"
                :items="documents"
                v-model="documents[0]"
                item-text="created"
                item-value="guid"
                @change="changeDocument"
              />
            </v-flex>
            <v-flex xs6 class="text-xs-center">
              <v-select
                label="Select Enrollment"
                :items="enrollments"
                v-model="enrollments[0]"
                item-text="created"
                item-value="guid"
                @change="changeEnrollment"
              />
            </v-flex>
          </v-layout>
          <v-layout row>
              <v-flex xs6 class="text-xs-center">
                <BaseImageRotator
                  :imgSrc="documentUrl"
                  :reset="resetRotation"
                />
              </v-flex>
              <v-flex xs6 class="text-xs-center">
                <BaseImageRotator
                  :imgSrc="enrollmentUrl"
                  :rotate="rotate"
                  :reset="resetRotation"
                />
              </v-flex>
          </v-layout>
        </v-container>
      </v-card-title>
      <v-card-actions>
        <BaseButton @clicked="closed" color="accent" text="Close" />
        <v-spacer />
        <BaseButton @clicked="rotateImage('left')" icon="rotate_left" />
        <BaseButton @clicked="rotateImage('right')" icon="rotate_right" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ues from '@/services/UserEnrollmentService';

export default {
  name: 'BiometricImageCompareDialog',
  props: {
    open: Boolean,
    enrollments: Array,
    documents: Array,
    authToken: String,
    disableEnrollment: Boolean,
    alertDetails: Object,
  },
  data: () => ({
    isOpen: false,
    rotate: null,
    resetRotation: false,
    enrollmentUrl: '',
    documentUrl: '',
    showEnrollment: false,
  }),
  computed: {
    getColor() {
      return !this.showEnrollment ? 'accent' : '';
    },
    hasScores() {
      return this.alertDetails
        && Object.prototype.hasOwnProperty.call(this.alertDetails, 'bioTypeScores') && Array.isArray(this.alertDetails.bioTypeScores) && this.alertDetails.bioTypeScores.length > 0;
    },
    score() {
      if (this.hasScores) {
        return this.alertDetails.bioTypeScores[0].score;
      }
      return '';
    },
    threshold() {
      if (this.hasScores) {
        return this.alertDetails.bioTypeScores[0].threshold;
      }
      return '';
    },
  },
  components: {
    BaseImageRotator: () => import('@/components/base/BaseImageRotator'),
    BaseButton: () => import('@/components/base/BaseButton'),
  },
  watch: {
    open(val) {
      this.isOpen = val;
    },
    enrollments(enrollments) {
      if (enrollments.length > 0) {
        this.setEnrollUrl(enrollments[0].guid);
      }
    },
    documents(documents) {
      if (documents.length > 0) {
        this.setDocUrl(documents[0].guid);
      }
    },
    disableEnrollment(disableEnrollment) {
      if (disableEnrollment) {
        this.showEnrollment = true;
      } else {
        this.showEnrollment = false;
      }
    },
  },
  methods: {
    closed() {
      this.isOpen = false;
      this.resetRotation = !this.resetRotation;
      this.$emit('closed');
    },
    rotateImage(dir) {
      if (dir === 'left') {
        this.rotate -= 1;
      } else {
        this.rotate += 1;
      }
    },
    changeEnrollment(guid) {
      this.setEnrollUrl(guid);
    },
    changeDocument(guid) {
      this.setDocUrl(guid);
    },
    setEnrollUrl(guid) {
      if (this.authToken) {
        this.enrollmentUrl = ues.getGuidSrc(guid, this.authToken);
      }
      return '';
    },
    setDocUrl(guid) {
      if (this.authToken) {
        this.documentUrl = ues.getGuidSrc(guid, this.authToken);
      }
      return '';
    },
  },
  mounted() {
    if (this.open) {
      this.isOpen = this.open;
    }
    if (this.enrollments.length > 0) {
      this.setEnrollUrl(this.enrollments[0].guid);
    }
    if (this.documents.length > 0) {
      this.setDocUrl(this.documents[0].guid);
    }
    if (this.disableEnrollment) {
      this.showEnrollment = true;
    } else {
      this.showEnrollment = false;
    }
  },
};
</script>
